/** Generated sass for domain-specific styling
 * by common\modules\domains\helpers\DomainScssWriter
 */


//// Custom scss for domain #375 (template1.nl) ////

// Bootstrap SORTED variables and domain specific custom colors: 

$dark: #212529;
$light: #f4f4f5;
$danger: #dc3545;
$warning: #ffc107;
$info: #0dcaf0;
$success: #198754;
$secondary: #d98254;
$primary: #82754d;

$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
);

//Add legacy styling from domain_css

//custom theme:
@import url("https://use.typekit.net/ixv8xre.css");


section {
    h1, h2, h3 {
        font-family: "orpheuspro", serif;
        font-weight: 400;
        font-style: normal;
    }
    h4 {
         font-family: "orpheuspro", serif;
        font-weight: 400;
        font-style: normal;
    }
}


/* bullets styling */
.about-content {
    ul {
        padding-left: 2rem !important;
        li {
            font-family: var(--tj-ff-p);
            font-size: 16px;
            font-weight: var(--tj-fw-normal);
            color: var(--tj-color-text-body);
            line-height: 26px;
            margin-bottom: 5px;
        }
    }
}


/* vertical align images in slideshow */
.owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}


$beauly-heading-primary: $primary;

$beauly-body: #333333;

$beauly-primary: $primary;
$beauly-dark: #222326;
$beauly-dark-2: #363636;

$beauly-dark-light: #EEEEEE;
$beauly-dark-light-2: #1e1f21;

$beauly-dark-deep: #FFFFFF;
$beauly-dark-deep-2: #181d24;

$beauly-primary-blur: rgba(189, 140, 98, 0.2);

$beauly-border-1: #ededed;
$beauly-border-2: #e9e9e9;
$beauly-border-3: #999999;
$beauly-border-4: #222326;
$beauly-border-5: rgba(0, 0, 0, 0.1);
$beauly-border-6: rgba(168, 168, 168, 0.3);
$beauly-border-7: rgba(227, 227, 227, 0.4);






/* expander */

.expander {
  &:hover {
    & .expand-button {
      text-decoration: underline;
    }
  }

  & .expand-button {
    color: var(--bs-primary);
    cursor: pointer;
    text-decoration: none;
  }

  & .expander-content {
    overflow: hidden;
    transition: 0.5s ease;
  }

  & .expand-button {
    & .more {
      display: inline;
    }

    & .less {
      display: none;
    }
  }

  &.expand {
    & .expander-content {
      transition: 0.5s ease-in;
    }

    & .expand-button {
      & .more {
        display: none;
      }

      & .less {
        display: inline;
      }
    }
  }
}


/* bs5 alerts */
.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    
    a {
        text-decoration: underline !important;
    }
}

.alert-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}
.alert-success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
}




section .tj-primary-btn, footer .tj-primary-btn {
    text-shadow: #9e9e9e 0 0 15px;
}


section {
    h1, h2, h3, h4, h5, h6 {
        color: #333333;
    }
    

    .tj-primary-btn {
        text-transform:initial;
    }
    
    
    .form-control {
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid var(--tj-color-border-6);
        padding: 15px 20px;
        border-radius: 0;
        font-family: var(--tj-ff-body);
    }
    
    
    .tj-outline-btn {
        background-color: transparent;
        padding: 15px 20px;
        border: 1px solid var(--tj-color-border-6);
        font-family: var(--tj-ff-primary);
        display: inline-block;
        font-size: 18px;
        font-weight: 500;
        line-height: 1;
        padding: 20px 35px;
        letter-spacing: 0;
        border-radius: 0;
        overflow: hidden;
        position: relative;
        z-index: 1;
    }
    
    .tj-outline-btn:after {
        background: #CCCCCC;
        content: "";
        height: 155px;
        left: -75px;
        opacity: 0.2;
        position: absolute;
        top: -50px;
        -webkit-transform: rotate(35deg);
        transform: rotate(35deg);
        transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
        z-index: -1;
    }
    .tj-outline-btn:hover:after {
        left: 120%;
        transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
    }
    
    .alert  {
        border-radius:0;
    }
    
    p {
        /* use font-weight of div around the p, not the p itself (builder)*/
        font-weight:unset;
    }

}

.site .mobile-side-menu .list-header{
    color:$primary;
}


section.page-header{
 h1, h2, h3, p {
        color: #FFFFFF;
    }
}

.site .hero-section::after {
    opacity: 0.10;
}

/* hero overlay */
.hero-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.3) 100%);
    z-index:1;
}
.hero-content{
    z-index:2;
}


h4 img {
    height: 35px;
    margin-right: 10px;
}

.icon-primary{
    filter:opacity(0.5) drop-shadow(0 0 0 $primary) saturate(300%);
}

.tj-primary-btn {
    width:fit-content;
}


@media (max-width: 991px){
    section {
        h1 {
            font-size: 60px;
        }
    }
}
@media (max-width: 767px){
    section {
    
        h1 {
            font-size:48px;
        }
        
        h2 {
            font-size:36px;
        }
        
        .tj-outline-btn {
            font-size: 15px;
            padding: 15px 25px;
            display: block;
            width: 100%;
            text-align:center;
        }
        
        .tj-primary-btn {
            display: block;
            width: 100%;
            text-align:center;
        }

    }
}


.custom-col {
    display: flex;
    flex-direction: column;
    padding: 30px !important;
    justify-content: center;
    @include media-breakpoint-up(lg) {
        padding: 30px 8.3333333333% 30px 8.3333333333% !important;
        &.download {
            padding: 5% 8.3333333333% 5% 8.3333333333% !important;
            background-color: #f1f1f1;
        }
    }
    &.rechts {
        background: rgba(143,159,98,.1);
    }
    
}




.placeholder-slider-container, .streamer-slider-container {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    list-style: none;
    .placeholder-wrapper, .streamer-wrapper {
        position: relative;
        z-index: 1;
        display: flex;
        box-sizing: content-box;
        width: 100%;
        height: 100%;
        transition-property: transform;
        .placeholder-item {
            min-height: 720px;
        }
         @media (max-width: 1200px) {
          .placeholder-item {
                min-height: 600px;
           }
        }
        @media (max-width: 991px) {
          .placeholder-item {
                min-height: 550px;
           }
        }
        @media (max-width: 767px) {
          .placeholder-item {
                min-height: 350px;
           }
        }
        .streamer-item {
            min-height: 500px;
        }
        .placeholder-item, .streamer-item {
            position: relative;
            width: 100%;
            transition-property: transform;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            flex-shrink: 0;
        }
    }
    .placeholder-button-next, .placeholder-button-prev {
        bottom: 20px;
    }
    .streamer-button-next, .streamer-button-prev {
        bottom: 50%;
        transform: translateX(-50%);
    }
    .placeholder-button-next, .placeholder-button-prev, .streamer-button-next, .streamer-button-prev {
        font-family: "FontAwesome";
        position: absolute;
        z-index: 999;
        width: 40px;
        height: 40px;
        opacity: .9;
        border-radius: 40px;
        background: #fff;

        align-items: center;
        text-rendering: auto;
        &:focus {
            border: none;
            outline: none;
        }
        i {
            font-size: 18px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #9dafbd;
        }
        &:hover {
            transition: ease-in-out .2s all;
            background-color: #cab162;
            i {
                color: $white;
            }
        }
    }
    .streamer-button-prev {
        left: 10%;
    }
    .streamer-button-next {
        right: 10%;
    }
    .placeholder-button-next {
        left: 90px;
    }
    .placeholder-button-prev {
        left: 40px;
    }
    .placeholder-scrollbar {
        position: absolute;
        z-index: 50;
        bottom: 3px;
        left: 1%;
        width: 98%;
        height: 5px;
    }
    .swiper-pagination {
        position: absolute;
        z-index: 10;
        transition: .3s opacity;
        transform: translate3d(0,0,0);
        text-align: center;;
    }
    .swiper-pagination.swiper-pagination-hidden {
        opacity: 0;
    }
    .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: 10px;
        left: 0;
        width: 100%;
    }

    .swiper-pagination-bullet {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        transition: .2s ease-in-out all;
        transform: scale(1.1);
        border-radius: 50%;
        background: #e4dbc1;
    }
    button.swiper-pagination-bullet {
        margin: 0;
        padding: 0;
        border: none;
        box-shadow: none;

        -webkit-appearance: none;
        appearance: none;
    }
    .swiper-pagination-clickable .swiper-pagination-bullet {
        cursor: pointer;
    }
    .swiper-pagination-bullet:only-child {
        display: none!important;
    }
    .swiper-pagination-bullet-active {
        width: 13px;
        height: 13px;
        background: $secondary;
    }
}




/* enlarge photo lightbox */
.modal-xl {
    --bs-modal-width: 90% !important;
}

/* hide grecaptcha-badge */
.grecaptcha-badge { 
    visibility: hidden; 
}



/* user account */

.dashboard-header:before {
    display:none;
}


.dashboard-header {

    .dashboard-header__top {
        background-color:rgba($primary, 0.85) !important;
    }
    
    @media (max-width: 1200px) {
        .dashboard-header__top {
            border-top: 16px solid $dark;
        }
    }
    
    .dashboard-header__menu-item.is-active .dashboard-header__menu-icon {
        color:#FFFFFF !important;
    }
    
    .dashboard-header__menu-link:hover .dashboard-header__menu-icon {
        color:#FFFFFF !important;
    }
    
    
    @media (min-width: 1200px) {
        .dashboard-header__menu-link:after {
            background-color: #FFFFFF !important;
        }
        
        
        .dashboard-header__menu-link {
            color:#EEEEEE !important;
        }
    }
    
    .dashboard-header__menu-icon {
        color:#EEEEEE  !important;
    }
    
}
.dashboard-wrapper {
    
    .dashboard-heading h2, .dashboard-heading .h2 {
        color: $dark !important;
    }
    
    
    .residential-account__search {
        background-color:rgba($primary, 0.3) !important;
    }
    
    .residential-account__search h4 {
        color:$dark !important;
    }
    
    .residential-account__search-text {
        color:$dark !important;
    }
    
    .residential-account__search-image {
        background-image: linear-gradient(to right, rgba($primary, 0.7) 0%, rgba($primary, 0.6) 100%) !important;
    }
    
    
    .residential-account__account {
        background-color:rgba($primary, 0.1) !important;
    }
    
    
    .residential-account__account-image {
       background-color:rgba($primary, 0.2) !important;
    }
    
    
    .btn-link-dark {
         color:$dark !important;
    }
    
    
    .btn.btn-pink {
        color: #fff;
        border-color: $primary !important;
        background: $primary !important;
    }
    
    .btn.btn-pink:hover {
        border-color: rgba($primary, 0.9) !important;
        background: rgba($primary, 0.9) !important;
    }
    
    
    .btn-success {
        color: #fff !important;
        border-color: $primary !important;
        background-color: $primary !important;
    }
    
    .btn-success:hover {
        color:#FFFFFF  !important;
        border-color: rgba($primary, 0.9) !important;
        background: rgba($primary, 0.9) !important;
    }
    
    
    .form-check .form-check-label {
        color:$primary !important;
    }
    
    .form-check .form-check-input:checked + .form-check-label:after {
        background-color:$primary !important;
    }
    
    
    .form-block {
        background: rgba($primary, 0.15) !important;
        
        label {
            color: $dark !important;
        }
        
        .subscription {
        
            .toggle-btn {
                background-color: $primary !important;
            }
        
            .toggle-btn.disabled {
                background-color: #EDEDEE !important;
            }
        }
    
        h6, .h6 {
            color:$dark !important;
        }
    }
    
    
    .btn-primary {
        background-color:$primary !important;
        border-color:$primary !important;
        color:#FFFFFF !important;
    }
    
    .btn-primary:hover {
        background-color: rgba($primary, 0.9) !important;
        border-color: rgba($primary, 0.9) !important;
    }
}


/* energielabel */


.energielabel {
    height: 24px;
    display: inline-block;
    position: relative;
    padding: 1px 10px 0 !important;
    margin: 0 24px 0 0;
    text-transform: uppercase;
    background-color:#009342 !important; 
    color:#FFF !important;
}

.energielabel:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 100%;
    border-style: solid;
    border-width: 12px 0 12px 12px;
    border-color: transparent transparent transparent #999;
    border-left-color:#009342;
}
.pageblock_8537 {
.hero-section{
     height: 80vh;
}

.hero-content .hero-title {
    font-size:70px;
}

.project-header {

  position: relative;
  overflow: hidden;
  height: 80vh;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .cta-sticker {
    padding: 10px 30px;
    border-radius: 3px;
    margin-top: 30px;
    font-size: 20px;
    display: inline-block;
  }

  .slider-nav {
    .carousel-next,
    .carousel-prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      i {
        background-color: transparent;
        color: rgba(white, .5);
        transition: all ease-in-out 0.2s;
        &:hover {
          color: rgba(white, 1);
        }
      }
    }
    .carousel-prev {
      left: 20px;
    }
    .carousel-next {
      right: 20px;
    }
  }
  .cta-link {
    position: absolute;
    bottom: 0;
    left: 15%;

    a {
      text-decoration: none;
    }

    .cta-link-downloads,
    .cta-link-images,
    .cta-link-prijslijst {
      padding: 0.5em 1em;
      transition: all 0.2s ease-in-out;
      border-top: 2px solid white;
      border-right: 2px solid white;
      border-left: 2px solid white;
      background-color: rgba(white, .8);

      &:hover {
        transform: translateY(10%);
        border-top: 2px solid $primary;
        border-right: 2px solid $primary;
        border-left: 2px solid $primary;
      }
      p::before {
        font-family: 'Font Awesome\ 5 Pro';
        display: inline-block;
        margin-right: 7.5px;
      }
    }
    .cta-link-images {
      p::before {
        content: ("\f302");
      }
    }
    .cta-link-downloads {
      p::before {
        content: ("\f019");
      }
    }
    .cta-link-prijslijst {
      p::before {
        content: ("\f0cb");
      }
    }
  }
}

.hero-section::after {
    opacity: 0.1;
}

}
.pageblock_8538 {

.page-menu {
    background-color: #f4f4f6
}

@media (max-width: 767.98px) {
    .page-menu {
        display: none
    }
}

.page-menu .menu {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    padding: 3em 0;
    list-style-type: none;
    align-items: center
}

.page-menu .menu li a {
    font-weight: 900;
    padding-right: 2em;
    text-decoration: none;
    color: $primary;
}

}
.pageblock_8539 {

.breadcrumbs-section .breadcrumbs {
    display: flex;
    flex-direction: row;
    padding: 3em 0;
    list-style-type: none;
    align-items: center
}

.breadcrumbs-section .breadcrumbs li {
    margin-right: 1em
}

.breadcrumbs-section .breadcrumbs li:first-child {
    font-weight: 900
}

.breadcrumbs-section .breadcrumbs a:hover {
    color: #223051
}

.breadcrumbs-section .breadcrumbs * {
     color: $primary
}

.breadcrumbs-section .breadcrumbs a {
    text-decoration: underline;
    color: $primary
}


}
.pageblock_8540 {
.lot-badge {
  width: 75px;
  height: 75px;
}

h1::after {
    margin-bottom: 10px;
}
}
.pageblock_8541 {



.table
{
    display: table;
    
    .table-row {
        display: table-row;
        
        & > div:nth-child(1){
            width: 40%;
        }
        & > div {
            display: table-cell;
            
            padding: .5rem .5rem;
        }
        
    }
    
    &.table-striped .table-row:nth-child(odd) {
        background-color: #f2f2f2;
    }   
}
}
.pageblock_8542 {
.media-item:nth-of-type(1n+5) {
   display:none;
}

.media-item .overlay {
    display: none;
    background-color: #000;
    opacity: 0.8;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    color: #fff;
}

.media-item:nth-child(4) .overlay {
    display: flex !important;
}




.media-container .media-wrapper {
	position: relative;
	display: inline-block;
	overflow: hidden;
	width: 100%;

	&:hover {
		a {
			.scaled-img {
				cursor: zoom-in;
				transform: scale(1.1);
			}
			&::after {
				opacity: 1;
			}
		}
	}
	a {
		position: relative;
		width: 100%;
		height: 200px; 
		display: block; 
		overflow: hidden;


		.scaled-img {
			position: relative;
			height: 100%;
			background-position: center;
			background-repeat: no-repeat; 
			background-size: cover;
			transition: all .15s ease-in-out;
		}

		&.large-img {
			height: 430px;
		}
	}
}



}
.pageblock_8543 {
.download-content{
    border: 2px solid $light;
    padding:10px;
    background-color: $light;
    color:$dark;
    text-decoration:none;
}

.download-content:hover {
    border: 2px solid $secondary;
    color:$dark;
}


}
.pageblock_8550 {
.news-img {
  background-position: center;
   aspect-ratio: 3 / 2; 
  background-repeat:no-repeat;
  background-size:cover;
}

.thumb {
    width:100px;
}
}
.pageblock_8551 {

.footer-area .footer-top {
    border-top: 1px solid var(--tj-color-border-5);
  
    .beauly-widget .widget-header-title {
        color: #5f5f5f;
    }
    
    .beauly-widget .subscribe-form .form-control {
        color: #212529;
        background-color: #FFFFFF;
    }
   
}

}
.pageblock_8746 {
.news-img {
    background-position: center;
   aspect-ratio : 3/2;
   background-size:cover;
}
}
.pageblock_8749 {
.hero-bg {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.1); /* black with 10% opacity */
     z-index:1;
 
}
.container {
    z-index:2;
}


/* disable the theme overleay (use .hero-bg instead) */
.hero-section::after {
    opacity: 0;
}


h1, h4{
    color:$white;
}

.circle {
    width: 250px;
    height: 250px;
    border-radius: 125px;
    bottom:80px;
    position:absolute;
    right:40px;
    font-size: 20px;
    color: $primary;
    line-height: 22px;
    text-align: center;
    background-color: rgba(198, 164, 67, .75);
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
    transform: rotate(12deg);
    margin-right: 30px;
}

@media (max-width: 1200px){
    .circle {
        width: 180px;
        height: 180px;
        border-radius: 90px;
        bottom:15px;
        right:0;
        bottom:30px;
        font-size: 18px;
        line-height: 20px;
        padding-top: 30px;
    }
}

@media (max-width: 767px){
    .circle {
        bottom:15px;
    }
}

min-height:500px;
}
.pageblock_8752 {
.item {
    min-width:200px;
    margin-bottom:20px;
}
}
.pageblock_10125 {
@media (max-width: 991px) {
    .about-section .row {
         flex-direction: column; //normal order
    }
}
}
.pageblock_10232 {
.primary-header .sub-menu li a {
    text-transform:initial;
}

.primary-header {
    .header-logo {
        .logo { 
            display:block; 
        }
        .logo-dark {
            display:none;
        }
        .logo, .logo-dark {
            width:100%;
            max-width:200px;
        }
    }
    
    
    .sub-menu {

        li {
            
            li:hover {
                background-color: $primary;
                a {
                    color:$white;
                }
            }
        }
    }
}



.header.header__sticky {
    background-color:$white;
    border-bottom:1px solid $light;
    
    
    .primary-header-inner .header-contact-area {
        a, i {
            color:$dark;
        }
    }
    
    .primary-header {
    
         .header-logo {
            .logo { 
                display:none; 
            }
            .logo-dark {
                display:block;
            }
        }
    
    
        .sub-menu{
            li a {
                color:$dark;
            }
            li li:hover a {
                color:$white;
            }
            li a:before {
               background-color: $dark;
            }
            
            .has-dropdown:after {
                color:$dark;
            }
        }
    }
    
    .header-right .tj-primary-btn {
        border: 1px solid $dark;
        color:$dark;
    }
    
    .tj-primary-btn:focus, .tj-primary-btn:hover {
        color: $dark;
    }
    
    .tj-primary-btn:after {
        background: lightgrey;
    }
 
}


.mobile-side-menu {
    background-color:$white;
    color:$dark;
    
     .side-menu-head .mobile-side-menu-close{
        background-color:$white;
        color: $dark;
        border:1px solid $light;
    }
    
    .side-menu-head .mobile-side-menu-close:hover {
        background-color: $primary;
        color: $dark;
    }
    
    
    .mean-bar .mean-nav.mean-nav > ul {
    
        li a {
            color:$dark;
        }
        
        li:not(:last-of-type) {
            border-bottom: 1px solid $light;
        }
    }
    
    .side-logo {
        max-width:175px;
    }   
    

   .side-menu-social ul li a {
        background-color:$white;
        border:1px solid $light;
   }
}




.header-menu-wrap .sub-menu li {
    margin: 0 30px;
}

.header.header__sticky .primary-header-inner .header-right .mobile-side-menu-toggle {
    color: #000;
}



/* margin fixes for menu (too wide in original template ) */
@media only screen and (min-width: 1601px) and (max-width: 1700px){
    .header-menu-wrap .sub-menu li {
        margin: 0 20px;
    }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px){
    .header-menu-wrap .sub-menu li {
        margin: 0 20px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px){
    .header-menu-wrap .sub-menu li {
        margin: 0 12px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
    .header-menu-wrap .sub-menu li {
        margin: 0 12px;
    }

    .header-menu-wrap .sub-menu .has-dropdown:after {
        top: 51%;
    }
}

@media (max-width: 991px){
    .header .primary-header-inner .header-right .mobile-side-menu-toggle {
        color: $white;
    }
    

    .header.header__sticky {
        display: block;
    }
}


@media (max-width: 767px){
    .header-right .header-logo img {
        width: inherit;
    }
}






}
.pageblock_15805 {
p {
    color:#FFFFFF !important;
    font-size:16px;
}

.amenities-icon img {
  filter: brightness(0) invert(1);
}
}
.pageblock_16451 {
h2 {
    font-size:20px;
    font-weight:300;
    color:white;
}
}
.pageblock_16452 {
.hero-section {
    height: 650px;
} 
 
@media (max-width: 1200px) {
    .hero-section {
        height: 600px;
    } 
}
@media (max-width: 991px) {
    .hero-section {
        height: 550px;
    } 
}
@media (max-width: 767px) {
    .hero-section {
        height: 400px;
    } 
}
}
.pageblock_8570 {

.container {
    z-index:2;
}


.hero-section::after {
    opacity: 0.10;
}


h1, h4{
    color:$white;
}

min-height:500px;
}
.pageblock_8571 {
.page-menu .menu {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    padding: 3em 0;
    list-style-type: none;
    align-items: center;
    
    li a {
       
        padding-right: 2em;
        text-decoration: none;
    }
}

}
.pageblock_8572 {
.building-type-badge {
  width: 75px;
  height: 75px;
}
}
.pageblock_8573 {
.media-item:nth-of-type(1n+5) {
   display:none;
}

.media-item .overlay {
    display: none;
    background-color: #000;
    opacity: 0.8;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    color: #fff;
}

.media-item:nth-child(4) .overlay {
    display: flex !important;
}




.media-container .media-wrapper {
	position: relative;
	display: inline-block;
	overflow: hidden;
	width: 100%;

	&:hover {
		a {
			.scaled-img {
				cursor: zoom-in;
				transform: scale(1.1);
			}
			&::after {
				opacity: 1;
			}
		}
	}
	a {
		position: relative;
		width: 100%;
		height: 200px; 
		display: block; 
		overflow: hidden;


		.scaled-img {
			position: relative;
			height: 100%;
			background-position: center;
			background-repeat: no-repeat; 
			background-size: cover;
			transition: all .15s ease-in-out;
		}

		&.large-img {
			height: 430px;
		}
	}
}



}
.pageblock_8574 {
.download-content{
    border: 2px solid $light;
    padding:10px;
    background-color: $light;
    color:$dark;
    text-decoration:none;
}

.download-content:hover {
    border: 2px solid $secondary;
    color:$dark;
}


}
.pageblock_9137 {
.price-list-table {

    .price-list-header {
        font-weight: bold;
    }

    .card {
        border: unset;

        .card-header {
            background-color: unset;
            border-top: 1px solid rgba(0,0,0,.125);
            border-bottom: unset;
            border-radius: unset;

            .status-available {
                color: #009900;
            }

            .status-option {
                color: #FFAA00;
            }

            .status-oral-agreement {
                color: #FF6600;
            }

            .status-sold {
                color: #CC0000;
            }

            &:hover {
                background-color: #F3F3F3;
            }
        }

        .card-content {
            .status-available {
                color: #fff;
                background-color: #009900;
            }

            .status-option {
                color: #fff;
                background-color: #FFAA00;
            }

            .status-oral-agreement {
                color: #fff;
                background-color: #FF6600;
            }

            .status-sold {
                color: #fff;
                background-color: #CC0000;
            }
        }
    }
}
}
.pageblock_8578 {
.event-img {
  background-position: center;
  height: 15em;
}

}
.pageblock_8587 {
section {
  background-color: rgba(170,139,20,.2);

  h1:before  {
    position: absolute;
    left: -25px;
    width: 4px;
    height: 90%;
    content: "";
    background-color: $primary;
  }

  .flex-container {

    .flex-item {
      margin:15px;
      background-color:white;
      padding:30px;
      text-align:center;
      box-shadow: 0px 0px 20px 6px lightgrey;

      h6 {
        font-size: .8em;
        color: #a98b14;
      }

      h3 {
        text-transform: none;
      }

      .border {
        display:block;
        width:150px;
        height:2px;
        background-color:#CCC;
        margin: auto;
        margin-bottom: 25px;
      }

      a {
        display:block;
        color: #a98b14;
        padding-bottom:1em;
      }


    }
  }
}


}
.pageblock_8588 {
section {
  background-color: #f4f4f5;


  .flex-container {

    .flex-item {
      margin:15px;
      background-color:white;
      padding:30px;
      text-align:center;
      box-shadow: 0px 0px 20px 6px lightgrey;

      h6 {
        font-size: .8em;
        color: #a98b14;
      }

      h3 {
        text-transform: none;
      }

      .border {
        display:block;
        width:150px;
        height:2px;
        background-color:#CCC;
        margin: auto;
        margin-bottom: 25px;
      }

      a {
        display:block;
        color: #a98b14;
        padding-bottom:1em;
      }


    }
  }
}


}
.pageblock_8589 {
.price-list-table {

    .price-list-header {
        font-weight: bold;
    }

    .card {
        border: unset;

        .card-header {
            background-color: unset;
            border-top: 1px solid rgba(0,0,0,.125);
            border-bottom: unset;
            border-radius: unset;

            .status-available {
                color: #009900;
            }

            .status-option {
                color: #FFAA00;
            }

            .status-oral-agreement {
                color: #FF6600;
            }

            .status-sold {
                color: #CC0000;
            }

            &:hover {
                background-color: #F3F3F3;
            }
        }

        .card-content {
            .status-available {
                color: #fff;
                background-color: #009900;
            }

            .status-option {
                color: #fff;
                background-color: #FFAA00;
            }

            .status-oral-agreement {
                color: #fff;
                background-color: #FF6600;
            }

            .status-sold {
                color: #fff;
                background-color: #CC0000;
            }
        }
    }
}
}
.pageblock_10278 {
#map {
    height: 800px !important;
}

button:first-of-type {
    display: block;
}

/**
  Buttons CSS
*/
.tj-secondary-btn, .btn-secondary {
    background: var(--tj-color-theme-secondary);
    color: black;
    font-family: var(--tj-ff-primary);
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    padding: 20px 35px;
    letter-spacing: 0;
    border-radius: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
    &:focus,
    &:hover {
        color: var(--tj-color-common-white);
    }
    span {
        position: relative;
        z-index: 20;
    }
    &:after {
        background: var(--tj-color-common-white);
        content: "";
        height: 155px;
        left: -75px;
        opacity: 0.2;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
        z-index: -1;
    }
    &:hover:after {
        left: 120%;
        transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
    }
    i {
        margin-left: 20px;
      
    }
}


}
.pageblock_10279 {
.price-list-table {

    .price-list-header {
        font-weight: bold;
    }

    .filter-no-results {
      padding: 15px;
      text-align: center;
    }

    .card {
        border: unset;

        .card-header {
            background-color: unset;
            border-top: 1px solid rgba(0,0,0,.125);
            border-bottom: unset;
            border-radius: unset;

            .status-available {
                color: #009900;
            }

            .status-option {
                color: #FFAA00;
            }

            .status-oral-agreement {
                color: #FF6600;
            }

            .status-sold {
                color: #CC0000;
            }

            &:hover {
                background-color: #F3F3F3;
            }
        }

        .card-content {
            .status-available {
                color: #fff;
                background-color: #009900;
            }

            .status-option {
                color: #fff;
                background-color: #FFAA00;
            }

            .status-oral-agreement {
                color: #fff;
                background-color: #FF6600;
            }

            .status-sold {
                color: #fff;
                background-color: #CC0000;
            }
        }
    }
}
}
.pageblock_8622 {
.image-grid {
	display: grid;

	gap: 0 0;
	grid-template-columns: 47.5% 5% 47.5%;
	grid-template-rows: 1fr 1fr;

	.image-1 {
		grid-area: 1 / 1 / 3 / 2;
	}
	.image-2 {
		grid-area: 1 / 3 / 3 / 3;
	}
}
}
.pageblock_8631 {
section {
  background-color:$light;

  h1:before  {
    position: absolute;
    left: -25px;
    width: 4px;
    height: 90%;
    content: "";
    background-color: $primary;
  }

  .flex-container {

    .flex-item {
      margin:15px;
      background-color:white;
      padding:30px;
      text-align:center;
      box-shadow: 0px 0px 20px 6px lightgrey;

      h6 {
        font-size: .8em;
        color: $secondary;
      }

      h3 {
        text-transform: none;
        font-size: 16px;
      }

      .border {
        display:block;
        width:150px;
        height:2px;
        background-color:#CCC;
        margin: auto;
        margin-bottom: 25px;
      }

      a {
        display:block;
        color: $secondary;
        padding-bottom:1em;
        text-decoration: underline;
        text-transform: initial;
        font-weight: normal !important;
        font-size: 1rem !important;
      }


    }
  }
}


}
.pageblock_8637 {
.faq-info .accordion .accordion-item .accordion-button {
    color: #333333;
}

.faq-info .accordion .accordion-item {
    border: 1px solid #CCCCCC;
}
}
.pageblock_8748 {
.news-img {
    background-position: center;
   aspect-ratio : 3/2;
   background-size:cover;
}
}
.pageblock_8646 {
.rent-calculator-container {
    .rent-calculator-left {
        background-color: #e8e8ea;
    }
    .rent-calculator-right {
          background-color: $light;
    }

    .row {
        padding-bottom: 10px;
    }

    .rent-calculator-right {
        padding-left: 100px;
    }

    @media screen and (min-width: 768px) {
        height: 550px;
        background: linear-gradient(to right, #e8e8ea 50%, $light 50%);
        position: relative;

        .rent-calculator-left:after {
            content: "";
            width: 0;
            height: 0;
            margin: -382px 0 0 -1px;
            border-style: solid;
            border-width: 275px 0 275px 49px;
            border-color: transparent transparent transparent #e8e8ea;
            position: absolute;
            left: 50%;
            top: 380px;
        }
    }
    .calculator-link  {
        background-color:$primary !important;
    }
}
}
.pageblock_8716 {
.page-header, .hero-bg {
    height:320px;
}
}
.pageblock_8718 {
.event-img {
    background-position: center;
    height: 15em;
}

}
.pageblock_12298 {
.page-header, .hero-bg {
    height:320px;
}
}
.pageblock_12486 {
.survey {
  &__form {
    border-radius: 16px;
    padding: 80px 40px;
    background-color: $gray-300;
    &__content {
      margin-bottom: 40px;
      .h5 {
        margin-bottom: 24px;
      }
    }
  }
  &__info {
    border-radius: 16px;
    padding: 48px;
    background-color: $gray-300;
    h6 {
      margin-bottom: 16px;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 16px;
    }
  }
}
}
.pageblock_14673 {
.blog-img {
    background-position: center;
    height: 15em;
}
}
.pageblock_14676 {
.blog-img {
    background-position: center;
    height: 15em;
}
}
